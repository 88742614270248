<template>
  <v-form ref="form" v-model="isValidForm" autocomplete="off" @submit.prevent="checkForm">
    <select-invest-companies
      ref="selectInvestCompanies"
      required
      :clearable="false"
      :rules="[v => !!v || 'Не выбран инвест-дом']"
      :params="{ forRuStocks: isRuStock }"
      @change="setInvestCompany"
    />

    <v-select
      v-model="forecast.analystId"
      :items="expertsByFirmId"
      label="Аналитик"
      item-value="expertId"
      item-text="expertName"
      required
      :rules="[v => !!v || 'Не выбран аналитик']"
    >
      <template v-slot:no-data>
        <div class="pa-2">Не выбран инвест-дом</div>
      </template>
    </v-select>

    <v-select
      v-model="forecast.recommendation"
      :items="recommendationsTypes"
      label="Рекомендация"
      item-value="uid"
      item-text="text"
      required
      :rules="[v => !!v || 'Не выбрана рекомендация']"
    />

    <v-text-field v-model="forecast.priceTarget" label="Прог. цена" />

    <date-picker v-model="forecast.date" label="Дата прогноза" />

    <v-checkbox v-model="expiredAtOnYear" label="На год" />

    <v-text-field
      v-if="expiredAtOnYear"
      :value="expiredAtText"
      label="Срок"
      readonly
      :rules="[v => !!v || 'Не выбран срок']"
    />

    <date-picker v-else v-model="forecast.expiredAt" label="Срок" :rules="[v => !!v || 'Не выбран срок']" />

    <v-text-field v-model="forecast.article" label="Ссылка на статью" />

    <div class="mt-4 text-right">
      <v-btn type="submit" color="primary mr-4" :loading="loading">Добавить прогноз</v-btn>
    </div>

    <stock-forecasts-update-confirm :is-open="isOpenModal" @close="isOpenModal = false" @save="createForecast" />
  </v-form>
</template>

<script>
import { ApiValidationError } from "~/core/api-validation-error";
import { DateHelper } from "~/core/date";
import { Numbers } from "~/core/filters/numbers";

import StockForecastsUpdateConfirm from "./update-confirm";

import SelectInvestCompanies from "~/components/experts/select-invest-companies";

const defaultCreateForecastDto = {
  analystId: "",
  recommendation: "buy",
  priceTarget: "",
  date: DateHelper.formatDate(Date.now()),
  article: "",
  expiredAt: ""
};

Object.freeze(defaultCreateForecastDto);

export default {
  props: {
    ticker: {
      type: String,
      required: true
    },
    prices: {
      type: Object,
      required: true
    },
    recommendationsTypes: {
      type: Array,
      required: true
    }
  },
  data() {
    return {
      loading: false,

      forecast: { ...defaultCreateForecastDto },

      isValidForm: true,

      expertsByFirmId: [],
      expiredAtOnYear: true,

      isOpenModal: false
    };
  },
  computed: {
    createForecastDto() {
      const date = new Date(this.forecast.date);
      const dateOnYear = new Date(date.setFullYear(date.getFullYear() + 1));

      const expiredAt = this.expiredAtOnYear ? DateHelper.formatDate(dateOnYear) : this.forecast.expiredAt;

      return {
        ...this.forecast,
        ticker: this.ticker,
        expiredAt
      };
    },
    expiredAtText() {
      return `1 год — ${DateHelper.getHumanFriendlyDate(this.createForecastDto.expiredAt)}`;
    },
    isRuStock() {
      return this.ticker.toUpperCase().slice(0, 3) === "RU:";
    }
  },
  watch: {
    "createForecastDto.priceTarget": {
      handler(value) {
        this.createForecastDto.priceTarget = Numbers.parseNumber(value);
      }
    }
  },
  methods: {
    async createForecast() {
      this.isOpenModal = false;

      if (!this.isValidForm) {
        this.$refs.form.validate();

        return;
      }

      this.loading = true;

      try {
        await this.$axios.$post("stock-ratings", this.createForecastDto);
        await this.$parent.refreshTable();

        this.clear();

        this.$snackbar.success("Прогноз создан");
      } catch (e) {
        const html = new ApiValidationError(e, [...Object.keys(this.createForecastDto), "error"]).toHtml();

        this.$snackbar.error("Не удалось создать прогноз: " + html);
      } finally {
        this.loading = false;
      }
    },

    async setInvestCompany(value) {
      if (value === "") {
        this.expertsByFirmId = [];

        return;
      }

      try {
        this.expertsByFirmId = await this.$axios.$get("experts", { params: { firmId: value, type: "select" } });
      } catch {
        // ignore
      }
    },

    clear() {
      this.forecast = { ...defaultCreateForecastDto };

      this.$refs.selectInvestCompanies.reset();

      this.$refs.form.resetValidation();

      this.expiredAtOnYear = true;
      this.expertsByFirmId = [];
    },
    checkForm() {
      const { priceTarget, recommendation } = this.createForecastDto;

      if (!this.isValidForm) {
        this.$refs.form.validate();

        return;
      }

      if (priceTarget === "") {
        this.createForecast();
      } else {
        const currentPrice = this.prices?.current?.value ?? this.prices.close.value;

        const potential = ((Numbers.parseNumber(priceTarget) - currentPrice) / currentPrice) * 100;

        if (
          (recommendation === "buy" && potential > 0 && potential <= 200) ||
          (recommendation === "hold" && potential >= -30 && potential <= 30) ||
          (recommendation === "sell" && potential < 0 && potential > -30)
        ) {
          this.createForecast();
        } else {
          this.isOpenModal = true;
        }
      }
    }
  },
  components: {
    SelectInvestCompanies,
    StockForecastsUpdateConfirm
  }
};
</script>
