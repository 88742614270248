<template>
  <div class="pa-3">
    <data-table
      ref="dataTable"
      item-key="id"
      url="stock-ratings"
      sort-by="date"
      :sort-desc="true"
      :headers="headers"
      :table-params="tableParams"
    >
      <template v-slot:[`item.expertName`]="{ item: forecast }">
        {{ forecast.expertName }}
      </template>

      <template v-slot:[`item.expertFirm`]="{ item: forecast }">
        {{ forecast.expertFirm }}
      </template>

      <template v-slot:[`item.recommendation`]="{ item: forecast }">
        {{ forecast.recommendation | consensus }}
      </template>

      <template v-slot:[`item.priceTarget`]="{ item: forecast }">
        {{ forecast.priceTarget | number({ fullPrecision: true }) }}
      </template>

      <template v-slot:[`item.action`]="{ item: forecast }">
        {{ forecast.action | analystAction }}
      </template>

      <template v-slot:[`item.date`]="{ item: forecast }">
        {{ forecast.date | date }}
      </template>

      <template v-slot:[`item.expiredAt`]="{ item: forecast }">
        {{ forecast.expiredAt | date }}
      </template>

      <template v-slot:[`item.article`]="{ item: forecast }">
        <a v-if="getUrlArticleForecast(forecast)" :href="getUrlArticleForecast(forecast)" target="_blank">Статья</a>

        <span v-else>—</span>
      </template>

      <template v-slot:[`item.actions`]="{ item: forecast }">
        <div class="d-flex">
          <v-btn icon color="accent" @click="openEditedForecast(forecast)">
            <v-icon small>mdi-pencil</v-icon>
          </v-btn>

          <v-btn icon color="error" @click="confirmDelete(forecast)">
            <v-icon small>mdi-delete</v-icon>
          </v-btn>
        </div>
      </template>
    </data-table>

    <div v-if="isShowExpert">
      <v-divider class="my-6" />

      <div class="text-h6 mb-3">Новый прогноз</div>

      <stock-forecasts-create-form :ticker="ticker" :prices="prices" :recommendations-types="recommendationsTypes" />
    </div>

    <stock-forecasts-edit
      :is-open-modal="isOpenModal"
      :fields-forecast="fieldsForecast"
      :recommendations-types="recommendationsTypes"
      :is-show-ticker="isShowTicker"
      @change-field="changeField"
      @close="resetForecastFields"
      @save="saveForecast"
    />

    <stock-forecasts-confirm-delete
      :is-open="paramsConfirmationDelete.isOpen"
      subtitle="Вы уверены, что хотите удалить рекомендацию?"
      @close="paramsConfirmationDelete.isOpen = false"
      @delete="deleteForecast(paramsConfirmationDelete.id)"
    />
  </div>
</template>

<script>
import { ApiValidationError } from "~/core/api-validation-error";
import { UpdateForecastDto } from "~/libs/stock/update-stock-dto";

import { editorForm } from "~/mixins/multi-editor/editor-form";

import StockForecastsEdit from "./edit";
import StockForecastsCreateForm from "./create-form";
import StockForecastsConfirmDelete from "./confirm-delete";

export default {
  mixins: [editorForm],
  props: {
    ticker: {
      type: String,
      default: ""
    },
    analystId: {
      type: String,
      default: ""
    },
    prices: {
      type: Object,
      default() {
        return {};
      }
    },
    isShowExpert: {
      type: Boolean,
      default: true
    },
    isShowTicker: {
      type: Boolean,
      default: false
    },
    tableParams: {
      type: Object,
      default() {
        return {};
      }
    }
  },
  data() {
    return {
      recommendationsTypes: [
        { uid: "buy", text: "Покупать" },
        { uid: "hold", text: "Держать" },
        { uid: "sell", text: "Продавать" }
      ],

      isOpenModal: false,
      fieldsForecast: {},

      paramsConfirmationDelete: {
        id: null,
        isOpen: false
      },
      stock: null
    };
  },
  computed: {
    headers() {
      const data = [
        { text: "Рекомендация", value: "recommendation", sortable: true, class: "nobr" },
        { text: "Прог. цена", value: "priceTarget", sortable: true },
        { text: "Действие", value: "action", sortable: true },
        { text: "Дата прогноза", value: "date", sortable: true },
        { text: "Срок прогноза", value: "expiredAt", sortable: true },
        { text: "Статья", value: "article", sortable: false },
        { text: "Действия", value: "actions", sortable: false }
      ];

      if (this.ticker) {
        return [
          { text: "Аналитик", value: "expertName", sortable: true },
          { text: "Компания", value: "expertFirm", sortable: true },
          ...data
        ];
      } else if (this.analystId) {
        return [{ text: "Тикер", value: "ticker", sortable: true }, ...data];
      } else {
        return [
          { text: "Аналитик", value: "expertName", sortable: true },
          { text: "Компания", value: "expertFirm", sortable: true },
          { text: "Тикер", value: "ticker", sortable: true },
          ...data
        ];
      }
    }
  },
  async mounted() {
    await this.$store.dispatch("forms/fetchExpertsByType", { expertType: "analyst" });
  },
  methods: {
    refreshTable() {
      return this.$refs.dataTable.update();
    },

    async deleteForecast(id) {
      try {
        await this.$axios.$delete(`stock-ratings/${id}`);

        await this.refreshTable();

        this.$snackbar.success("Удалено");
      } catch (e) {
        const html = new ApiValidationError(e).toHtml();

        this.$snackbar.error("Не удалось удалить: " + html);
      } finally {
        this.paramsConfirmationDelete.isOpen = false;
      }
    },

    confirmDelete(forecast) {
      this.paramsConfirmationDelete.id = forecast.id;

      this.paramsConfirmationDelete.isOpen = true;
    },

    getUrlArticleForecast(forecast) {
      return forecast?.article?.url;
    },

    openEditedForecast(forecast) {
      this.isOpenModal = true;

      this.fieldsForecast = {
        ...forecast,
        analystId: forecast.expertId,
        ticker: this.isShowTicker ? forecast.ticker : this.ticker
      };
    },

    resetForecastFields() {
      this.isOpenModal = false;

      this.fieldsForecast = {};
    },

    changeField(payload) {
      this.commitChange(this.fieldsForecast, payload);
    },

    async saveForecast() {
      try {
        const { id } = this.fieldsForecast;

        await this.$axios.$put(`stock-ratings/${id}`, new UpdateForecastDto(this.fieldsForecast));

        await this.refreshTable();

        this.$snackbar.success("Прогноз обновлен");

        this.isOpenModal = false;

        this.fieldsForecast = {};
      } catch (e) {
        const html = new ApiValidationError(e, Object.keys(this.fieldsForecast)).toHtml();

        this.$snackbar.error("Не удалось обновить прогноз: " + html);
      }
    }
  },
  components: {
    StockForecastsEdit,
    StockForecastsCreateForm,
    StockForecastsConfirmDelete
  }
};
</script>
