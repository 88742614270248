<template>
  <v-select
    v-model="analystId"
    :items="optionsCopy"
    :label="label"
    item-value="expertId"
    item-text="expertName"
    :loading="loading"
    searchable
    clearable
  >
    <template v-slot:no-data>
      <div v-if="loading">Загрузка...</div>
    </template>

    <template v-slot:prepend-item>
      <v-list-item>
        <v-list-item-content>
          <v-text-field v-model="fieldSearch" placeholder="Поиск" clearable @input="searchExpert" />
        </v-list-item-content>
      </v-list-item>
    </template>
  </v-select>
</template>

<script>
export default {
  props: {
    value: {
      type: String,
      required: true
    },
    label: {
      type: String,
      required: true
    }
  },
  data() {
    return {
      optionsCopy: [],
      fieldSearch: null
    };
  },
  computed: {
    experts() {
      return this.$store.state.forms.expertsByType;
    },
    loading() {
      return this.experts.lenght === 0;
    },
    analystId: {
      get() {
        return this.value;
      },
      set(value) {
        this.fieldSearch = null;

        this.$emit("input", value);

        this.optionsCopy = [...this.experts];
      }
    }
  },
  watch: {
    experts: {
      immediate: true,
      handler() {
        this.optionsCopy = [...this.experts];
      }
    }
  },
  methods: {
    searchExpert(expert) {
      if (!expert) {
        this.optionsCopy = [...this.experts];
      }

      this.optionsCopy = this.optionsCopy.filter(
        value => value.expertName.toLowerCase().indexOf(expert.toLowerCase()) > -1
      );
    }
  }
};
</script>
