<template>
  <v-select
    ref="select"
    v-model="value"
    :items="optionsCopy"
    item-value="id"
    item-text="name"
    label="Инвест-дом"
    return-object
    searchable
    :clearable="clearable"
    required
    :rules="rules"
  >
    <template v-slot:no-data>
      <div v-if="loading">Загрузка...</div>
    </template>

    <template v-slot:prepend-item>
      <v-list-item>
        <v-list-item-content>
          <v-text-field v-model="fieldSearchCountry" placeholder="Поиск" clearable @input="searchCountry" />
        </v-list-item-content>
      </v-list-item>
    </template>
  </v-select>
</template>

<script>
export default {
  props: {
    expert: {
      type: Object,
      default() {
        return {
          firmId: "",
          firmName: ""
        };
      }
    },
    rules: {
      type: Array,
      default() {
        return [];
      }
    },
    clearable: {
      type: Boolean,
      default: true
    },
    params: {
      type: Object,
      default() {
        return {};
      }
    }
  },
  data() {
    return {
      loading: false,
      optionsCopy: [],
      fieldSearchCountry: null
    };
  },
  computed: {
    value: {
      get() {
        return {
          id: this.expert.firmId,
          name: this.expert.firmName
        };
      },
      set(investCompany) {
        const id = investCompany?.id ? investCompany.id : "";

        this.fieldSearchCountry = null;

        this.$emit("change", id);

        this.optionsCopy = [...this.options];
      }
    },
    options() {
      return this.$store.state.forms.expertInvestmentCompanies;
    }
  },
  watch: {
    options: {
      immediate: true,
      deep: true,
      handler() {
        this.optionsCopy = [...this.options];
      }
    }
  },
  created() {
    this.fetch();
  },
  methods: {
    async fetch() {
      try {
        this.loading = true;

        await this.$store.dispatch("forms/fetchInvestmentCompanies", this.params);
      } catch {
        //ignore
      } finally {
        this.loading = false;
      }
    },
    searchCountry(investCompany) {
      if (!investCompany) {
        this.optionsCopy = this.options;
      }

      this.optionsCopy = this.optionsCopy.filter(
        value => value.name.toLowerCase().indexOf(investCompany.toLowerCase()) > -1
      );
    },
    reset() {
      this.$refs.select.reset();
    }
  }
};
</script>
