<template>
  <v-dialog :value="isOpenModal" max-width="600px" @click:outside="$emit('close')">
    <v-card>
      <v-card-title>
        <span class="text-h6">Редактирование прогноза</span>
      </v-card-title>

      <v-card-text>
        <v-container>
          <v-row>
            <v-col v-if="!isShowTicker" cols="12" sm="6">
              <experts-select v-model="analystId" label="Аналитик" />
            </v-col>

            <v-col v-if="isShowTicker" cols="12" sm="6">
              <v-text-field :value="fieldsForecast.ticker" label="Тикер" @input="setField('ticker', $event)" />
            </v-col>

            <v-col cols="12" sm="6">
              <v-select
                v-model="fieldsForecast.recommendation"
                :items="recommendationsTypes"
                label="Рекомендация"
                item-value="uid"
                item-text="text"
                required
                @input="setField('recommendation', $event)"
              />
            </v-col>

            <v-col cols="12" sm="6">
              <v-text-field v-model="priceTarget" label="Цена прогноза" />
            </v-col>

            <v-col cols="12" sm="6">
              <date-picker :value="fieldsForecast.date" label="Дата прогноза" @input="setField('date', $event)" />
            </v-col>

            <v-col cols="12" sm="6">
              <date-picker
                :value="fieldsForecast.expiredAt"
                label="Срок прогноза"
                @input="setField('expiredAt', $event)"
              />
            </v-col>

            <v-col cols="12">
              <v-text-field v-model="articleUrl" label="Статья" />
            </v-col>
          </v-row>
        </v-container>
      </v-card-text>

      <v-card-actions>
        <v-spacer />
        <v-btn color="blue darken-1" text @click="$emit('close')">Отмена</v-btn>
        <v-btn color="blue darken-1" text @click="$emit('save')">Сохранить</v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
import { editorFields } from "~/mixins/multi-editor/editor-fields";

import ExpertsSelect from "~/components/shared/experts/select";

export default {
  mixins: [editorFields],
  props: {
    isOpenModal: {
      type: Boolean,
      required: true
    },
    fieldsForecast: {
      type: Object,
      required: true
    },
    recommendationsTypes: {
      type: Array,
      required: true
    },
    isShowTicker: {
      type: Boolean,
      default: false
    }
  },
  computed: {
    articleUrl: {
      get() {
        return this.fieldsForecast.article?.url;
      },
      set(value) {
        return this.setField("article", value);
      }
    },
    priceTarget: {
      get() {
        return this.fieldsForecast.priceTarget;
      },
      set(value) {
        this.setField("priceTarget", value.replace(",", "."));
      }
    },
    analystId: {
      get() {
        return this.fieldsForecast?.analystId ?? "";
      },
      set(value) {
        return this.setField("analystId", value);
      }
    }
  },
  components: {
    ExpertsSelect
  }
};
</script>
